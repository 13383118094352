<template>
  <v-row>
    <v-col cols="12" v-if="editing" class="pt-0">
      <v-card flat>
        <v-card-title v-text="title" class="text-overline px-0" />

        <v-card-text class="pa-0">
          <v-row>
            <!-- New password -->
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="password"
                :append-icon="icon"
                :type="fieldType"
                @click:append="showPw = !showPw"
                label="Password reset"
                placeholder="Enter new password"
                :loading="loading"
                :error-messages="errors"
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                autocomplete="new-password"
                counter
                dense
                required
              />
            </v-col>

            <!-- Confirmation -->
            <v-col cols="12" class="pb-0 text-right">
              <pibot-pw-confirm
                color="success"
                depressed
                small
                :loading="loading"
                :disabled="disabled"
                @confirmed="validateAndUpdate"
              >
                Update password

                <template #text>
                  Please authorize yourself by entering your password.
                </template>
              </pibot-pw-confirm>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { containsNumeric } from '@/utils/mixins/forms/validators'

export default {
  name: 'pibot-settings-password-reset-admin',
  mixins: [validationMixin],
  components: {
    'pibot-pw-confirm': () => import('@/components/buttons/PasswordConfirm')
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
      containsNumeric
    }
  },
  data: () => ({
    title: 'Password',
    showPw: false,
    password: ''
  }),
  computed: {
    ...mapState({
      editing: state => state.settings.editing,
      loading: state => state.settings.loading
    }),
    fieldType () {
      return this.showPw ? 'text' : 'password'
    },
    icon () {
      return this.showPw ? 'visibility_off' : 'visibility'
    },
    disabled () {
      return this.$v.$invalid
    },
    errors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required.')
      !this.$v.password.minLength && errors.push('Password needs to have a minimum length of 6.')
      !this.$v.password.containsNumeric && errors.push('Password needs to have a number in it.')
      return errors
    }
  },
  methods: {
    ...mapActions({
      tryProfileUpdate: 'settings/socket_tryProfileUpdate'
    }),
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    validateAndUpdate () {
      const isValid = this.validate()
      if (!isValid) return

      const payload = {
        _id: this.editing._id,
        password: this.password
      }

      this.$emit('loading', true)

      this.tryProfileUpdate(payload)
        .then(() => {
          this.$emit('success', 'Password successfully updated')
          this.password = ''
          this.$v.$reset()
        })
        .catch(error => {
          this.$emit('error', error.message)
        })
        .finally(() => {
          this.$emit('loading', false)
        })
    }
  }
}
</script>
